@import "../../vars.css";

.container {
  margin-top: 76px;
  color: var(--color-text);
  margin-left: 8%;
  margin-right: 8%;
}

.title {
  font-size: 35px;
  font-weight: 700;
  letter-spacing: 1.75px;
  text-transform: uppercase;
}

.projects {
  margin-top: 37px;
  display: flex;
  flex-direction: column;
  gap: 15px;
  width: 100%;
}
