@import "../../vars.css";

.container {
  color: var(--color-text);
  margin-top: 120px;
  background-color: var(--color-secondary);
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  width: 100%;
  padding: 58px 10%;
  gap: 10px;
}

.footer{
  color: var(--color-text);
  margin-top: 120px;
  background-color: var(--color-secondary);
}

.text h2 {
  font-size: 80px;
  font-weight: 700;
  letter-spacing: 4px;
}

.text p {
  font-size: 40px;
  font-weight: 400;
  letter-spacing: 2px;
}

.links {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  list-style: none;
  gap: 26px;
}

.link {
  display: flex;
  align-items: center;
  gap: 25px;
  text-wrap: wrap;
}

.link a {
  color: var(--color-text);
  text-decoration: none;
  font-size: 30px;
  font-weight: 400;
  letter-spacing: 1.5px;
}

@media screen and (max-width: 830px) {
  .container {
    margin-top: 50px;
    flex-direction: column;
    gap: 23px;
  }

  .text {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .text p {
    text-align: center;
  }
  .links {
    align-items: center;
  }
  .link a{
    word-break: break-all;
  }
}
