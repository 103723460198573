@import "../../vars.css";

.container {
  display: flex;
  flex-direction: column;
  border-radius: 10px;
  background: linear-gradient(180deg, #576cbc 0%, #132a53 100%);
  box-shadow: 0 16px 14px 0 #04152d;
  padding: 18px 24px;
  width: 355px;
}

.image {
  height: 200px;
  width: 200px;
  margin-left: 30px;
  margin-right: 30px;
  margin-bottom: 26px;
}

.title {
  height: 80px;
  font-size: 25px;
  font-weight: 700;
}
.provider {
  margin-top: 8%;
}

.links {
  width: 100%;
  margin-top: 26px;
  display: flex;
  justify-content: space-around;
}

.link {
  text-decoration: none;
  color: var(--color-text);
  font-size: 25px;
  font-weight: 600;
  border-radius: 100px;
  background: var(--color-primary);
  padding: 7px 22px;
  background-image: linear-gradient(
    100deg,
    #04152d 0%,
    rgba(57, 7, 224, 0) 100%
  );
  background-size: 0 100%;
  background-repeat: no-repeat;
  transition: 0.4s;
}

.link:hover {
  background-size: 100% 100%;
}

@media screen and (max-width: 830px) {
  .container {
    text-align: center;
    width: 100%;
  }
  .title {
    height: 100px;
    word-wrap: break-word;
  }
  .link {
    font-size: 25px;
  }  
}
