@import url("../../vars.css");

.container {
  background-color: var(--color-bg);
  margin-top: 80px;
  margin-left: 8%;
  margin-right: 8%;
}
.title {
  color: var(--color-text);
  font-size: 35px;
  font-weight: 600;
  letter-spacing: 1.75px;
  text-transform: uppercase;
}

.content {
  margin-top: 5%;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
}

.skills {
  width: 35%;
  display: flex;
  flex-wrap: wrap;
  gap: 25px;
}

.skillImgContainer {
  background-color: var(--color-secondary);
  border-radius: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 120px;
  height: 120px;
  
}

.skill{
  transition: transform 0.2s; 
}

.skill:hover {
  transform: scale(
    1.3
  ); 
}
.skillImgContainer img {
  width: 75px;
}

.skills p {
  text-align: center;
  font-size: 25px;
  color: var(--color-text);
  font-weight: 500;
}

.experience {
  width: 55%;
  display: flex;
  flex-direction: column;
  gap: 40px;
}

.expItem {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 17px;
  background: #19376d;
  background: linear-gradient(90deg, #19376d 0%, rgba(255, 255, 255, 0) 100%);
  padding: 25px;
  border-radius: 10px;
}

.expItemHeader {
  display: flex;
  gap: 17px;
  margin-bottom: 10px;
}
.expItemHeader img {
  width: 75px;
}
.expItemHeader p{
  font-size: 20px
}

.expItemDetails {
  font-family: var(--font-roboto);
}

.expItemDetails h3 {
  font-size: 25px;
  font-weight: 500;
}

.expItemDetails ul {
  margin-top: 6px;
  list-style-position: inside;
  padding: 10px;
  font-size: 25px;
  list-style: disc;
  margin-left: 17px;
}

.listItem{
  margin-top: 4%;
}
@media screen and (max-width: 830px) {
  .container{
    margin-top: 30px;
  }
  .content {
    flex-direction: column;
    align-items: center;
    gap: 34px;
  }
  .title{
    font-size: 45px;
    text-align: center;
    margin-bottom: 10%;
  }
  .skills {
    width: 100%;
    justify-content: center;
  }

  .experience {
    width: 100%;
    gap: 10px;
  }
  
  .expItem{
    background: linear-gradient(180deg, #19376d 0%, rgba(255, 255, 255, 0) 100%);
  }
}
