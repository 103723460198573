@import "../../vars.css";

.container {
  margin-top: 76px;
  color: var(--color-text);
  margin-left: 1%;
  margin-right: 1%;
}

.scrollContainer {
  position: relative;
  left: 10%;
  display: flex;
  align-items: center;
}
.title {
  font-size: 35px;
  font-weight: 700;
  margin-left: 7%;
  letter-spacing: 1.75px;
  text-transform: uppercase;
}

.certifications {
  justify-content: center;
  margin-top: 37px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  padding: 20px;
  gap: 25px;
  width: 65vw;
  overflow-x: scroll;
  scrollbar-width: none;
  scroll-behavior: smooth;
}

.scrollArrows {
  color: #576cbc;
  font-size: 45px;
  margin: 1%;
  transition: transform 0.2s;
}
.scrollArrows:hover {
  transform: scale(1.3);
}

.scrollArrowsMobile {
  color: #576cbc;
  font-size: 45px;
  margin: 1%;
}
.mobileArrowContainer {
  display: none;
}
@media screen and (max-width: 830px) {
  .container {
    margin-left: 0;
    margin-right: 0;
  }
  .scrollContainer {
    left: 0;
    width: 100%;
    flex-direction: column;
  }
  .mobileArrowContainer {
    margin-top: 20px;
    display: flex;
    gap: 20px;
  }
  .scrollArrows {
    display: none;
  }
  .title {
    text-align: center;
    margin: 0;
  }
  .certifications{
    width: 77%;
    padding: 0px;
  }
}
