@import "../../vars.css";

.container {
  display: flex;
  flex-direction: row;
  border-radius: 10px;
  gap: 25px;
  background: linear-gradient(90deg, #19376d 0%, #132a53 100%);
  box-shadow: 0 16px 14px 0 #04152d;
  padding: 18px 24px;
}

.skillContainer{
  margin-top: 20px;
  width: 100%;
  display: flex;
  justify-content: space-between;
}
.innerContainer{
  width: 100%;
}
.image {
  margin-bottom: 26px;
  height: 200px;
  width: 280px;
}

.title {
  font-size: 25px;
  font-weight: 700;
}

.description {
  margin-top: 6px;
  font-size: 25px;
  font-weight: 400;
}

.skills {
  width: 100%;
  margin-top: 14px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 6px;
  list-style: none;
}

.skill {
  font-size: 25px;
  font-weight: 400;
  border-radius: 100px;
  background: var(--color-dark);
  padding: 2px 22px;
}

.links {
  display: flex;
  margin-top: 26px;

}

.link {
  text-decoration: none;
  color: var(--color-text);
  font-size: 30px;
  font-weight: 600;
  border-radius: 100px;
  background: var(--color-primary);
  margin: 10px;
  padding: 1px 22px;
  background-image: linear-gradient(
    100deg,
    #04152d 0%,
    rgba(57, 7, 224, 0) 100%
  );
  background-size: 0 100%;
  background-repeat: no-repeat;
  transition: 0.4s;
}
.link:hover{
  background-size: 100% 100%;
}

@media screen and (max-width:850px) 
{
  .container{
    flex-direction: column;
    background: linear-gradient(180deg, #19376d 0%, #132a53 100%);
  }

  .title{
    text-align: center;
  }

  .description{
    text-align: justify;
  }
  .skillContainer{
    flex-direction: column;
  }
  .skills{
    justify-content: center;
  }
  .links{
    align-items: center;
    justify-content: center;
  }
  .image {
    margin-bottom: 26px;
    height: 200px;
    width: 70vw;
  }
}
