@import url("../../vars.css");

.navbar {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-top: 61px;
  z-index: 2;
  margin: 0 10%;
}

.title {
  font-size: 30px;
  font-weight: 600;
  text-decoration: none;
  color: var(--color-text);
}

.menuBtn {
  display: none;
}

.menu {
  display: flex;
}

.menuItems {
  display: flex;
  gap: 47px;
  list-style: none;
}

.menuItems a {
  font-size: 25px;
  color: var(--color-text);
  text-decoration: none;
}

.links {
  padding: 15px;
  background-image: linear-gradient(
    90deg,
    rgba(165, 215, 232, 0.42) 0%,
    rgba(255, 255, 255, 0) 100%
  );
  border-radius: 15px;
  background-size: 0 100%;
  background-repeat: no-repeat;
  transition: 0.4s;
}
.links:hover{
  background-size: 100% 100%;
}

@media screen and (max-width: 830px) {
  .navbar {
    flex-direction: row;
    align-items: flex-start;
  }

  .menu {
    position: absolute;
    right: 0;
    margin-right: 10%;
    flex-direction: column;
    align-items: flex-end;
    gap: 11px;
    z-index: 3;
  }

  .menuBtn {
    display: block;
    cursor: pointer;
  }

  .menuItems {
    display: none;
    flex-direction: column;
    align-items: center;
    gap: 13px;
    border-radius: 10px;
    background: rgb(25, 55, 109);
    background: linear-gradient(
      2deg,
      rgba(25, 55, 109, 0.2) 0%,
      rgba(25, 55, 109, 1) 100%
    );
    padding: 24px 33px;
    box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.25);
  }

  .menuOpen {
    display: flex;
    z-index: 1;
  }
}
